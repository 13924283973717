// This component contains ONLY the code relevant to render a preview
// of each theme, consisting of a card with a background, a title, body
// text, and a link, as well as a theme font loader.
import { Box, Flex, FlexProps, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { Trans } from '@lingui/macro'
import { memo } from 'react'

import { isThemeDark, Theme } from 'modules/theming'
import { ThemeFontLoader } from 'modules/theming/components/FontLoader'
import { CARD_CONTENT_CLASS } from 'modules/tiptap_editor/extensions/Card'
import {
  getBackgroundProps,
  getDocOrThemeBackground,
} from 'modules/tiptap_editor/styles/backgroundStyles'
import { THUMBNAIL_RESIZE_PARAMS } from 'utils/image'

import { getThemeStylesheet } from '../styles'
import { HEADING_CLASS } from '../styles/heading'
import { getThemeCSSVars } from '../styles/variables'
import { getThemeBase } from '../themeBases'

type ThemePreviewThumbnailProps = {
  theme: Theme
  variant?: 'outline' | 'ghost'
} & FlexProps

export const ThemePreviewThumbnail = memo(
  ({
    theme,
    variant = 'outline',
    ...flexProps
  }: ThemePreviewThumbnailProps) => {
    const { contentStyles } = theme.config
    const background = getDocOrThemeBackground(theme)
    const isDark = isThemeDark(theme)
    const cssVars = getThemeCSSVars(theme)
    const themeStyles = getThemeStylesheet(theme, false)
    const isGhost = variant === 'ghost'
    const themeBase = getThemeBase(theme)
    const backgroundProps = getBackgroundProps(
      background,
      isDark,
      THUMBNAIL_RESIZE_PARAMS
    )
    const maybeFirstAccentBg = theme.config.accentBackgrounds?.[0]
    const accentBgProps = maybeFirstAccentBg
      ? getBackgroundProps(maybeFirstAccentBg, isDark, THUMBNAIL_RESIZE_PARAMS)
      : {}

    const cardContainerProps = maybeFirstAccentBg
      ? {
          display: 'grid',
          gridTemplateRows: 'minmax(2.5em, auto) auto',
          gridTemplateAreas: `"accent" "body"`,
          gridTemplateColumns: '1fr',
          overflow: 'hidden',
        }
      : {}

    return (
      <Box css={cssVars} sx={themeStyles} w="100%">
        <Flex
          borderTopRadius={isGhost ? 'none' : 'md'}
          boxShadow="sm"
          _focus={{
            boxShadow: 'outline',
          }}
          p={'1em'}
          transitionProperty="common"
          transitionDuration="normal"
          {...backgroundProps}
          backgroundAttachment="initial"
          direction="row"
          align="center"
          {...flexProps}
        >
          <ThemeFontLoader theme={theme} />
          {/* The card container */}
          <Box
            sx={{
              ...themeBase.cardSx,
              ...contentStyles,
            }}
            {...cardContainerProps}
            borderRadius="base"
            className={`${CARD_CONTENT_CLASS} ${isDark ? 'is-dark' : ''}`}
            color={isDark ? 'white' : 'gray.900'}
            flex={1}
            pointerEvents="none" // Prevent hovers
          >
            {maybeFirstAccentBg && (
              <Box gridArea={`"accent"`} {...accentBgProps} />
            )}
            <Box
              gridArea={maybeFirstAccentBg ? `"body"` : undefined}
              py={maybeFirstAccentBg ? 2 : 4}
              px={4}
            >
              <Text
                className="block-title"
                mt={'0 !important'}
                mb={`${
                  maybeFirstAccentBg ? '0' : 'var(--chakra-space-1)'
                } !important`}
              >
                <Text
                  as="span"
                  className={cx('title', HEADING_CLASS)}
                  fontSize="1.6em !important"
                  paddingBottom="0 !important"
                >
                  <Trans>Title</Trans>
                </Text>
              </Text>
              <Text
                fontSize="0.9em !important"
                noOfLines={3}
                className="block block-paragraph"
                my="0 !important"
              >
                <Trans>
                  Body &{' '}
                  <Text as="a" className="link">
                    link
                  </Text>
                </Trans>
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    )
  }
)

ThemePreviewThumbnail.displayName = 'ThemePreviewThumbnail'
