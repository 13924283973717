import { SystemStyleObject } from '@chakra-ui/react'

export const placeholderStyles: SystemStyleObject = {
  content: 'attr(placeholder)',
  position: 'absolute',
  top: 0,
  opacity: 0.3,
  pointerEvents: 'none',
  left: 0,
  width: '100%',
  color: 'var(--heading-color)',
  lineClamp: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  boxOrient: 'vertical',
  wordBreak: 'break-all',
  height: '100%',
  whiteSpace: 'nowrap',
}
