import { SystemStyleObject } from '@chakra-ui/react'

import {
  HEADING_MARGINS,
  PARAGRAPH_MARGINS,
} from 'modules/tiptap_editor/styles/constants'
import { placeholderStyles } from 'modules/tiptap_editor/styles/placeholderStyles'

import { HANDLE_HOVERING_ATTR } from '../DragDrop/GlobalDragHandlePlugin'

export const SmartLayoutStyles = (isMobile: boolean): SystemStyleObject => ({
  '.block-smartLayout': {
    // Prevent NodeViewContent divs from affecting grid/flex layouts
    '[data-node-view-content-outer="smartLayout"]': {
      display: 'contents',
    },
    '[data-node-view-content-inner="smartLayout"]': {
      display: 'contents',
    },
    '[data-selection-ring]': {
      // Border that appears on hover
      outlineStyle: 'none',
      outlineWidth: '1px',
      outlineColor: 'var(--grid-outline-color)',
    },
    '[data-selection-ring="padded"]': {
      // Render the ring offset from the content
      outlineOffset: '0.25em',
      '.add-button': {
        m: '-0.25em',
      },
    },
    '&:not(.is-focused)': {
      '[data-content-empty=true] > [data-node-view-content]': {
        height: 0,
      },
    },
    '&.is-focused': {
      '[data-content-empty=true]': {
        '[placeholder]::before': placeholderStyles,
      },
    },
    // Hover
    [`&.is-focused, &:hover, &[${HANDLE_HOVERING_ATTR}]`]: {
      '.is-editable': {
        '[data-selection-ring]': {
          outlineStyle: 'solid',
        },
        '.add-button': {
          opacity: 1,
        },
      },
    },
  },
  '.node-smartLayoutCell': {
    // Prevent NodeViewWrapper divs from affecting grid/flex layouts
    display: 'contents',
    '> [data-node-view-wrapper]': {
      display: 'contents',
    },

    '[data-node-view-content-inner="smartLayoutCell"] > .first-block': {
      mt: [0, 0],
    },

    '[data-node-view-content-inner="smartLayoutCell"] > .last-block': {
      mb: [0, 0],
    },

    '.slash-menu-prompt': {
      display: 'none',
    },

    '.block-heading, .block-title': {
      ...HEADING_MARGINS(isMobile).tight,
    },
    '.block-paragraph': {
      ...PARAGRAPH_MARGINS(isMobile).tight,
    },
  },
})
