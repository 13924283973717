import { css, Theme } from '@chakra-ui/react'
import { Global, Interpolation } from '@emotion/react'
import { memo } from 'react'

import { CARD_CONTENT_CLASS } from 'modules/tiptap_editor/extensions/Card'
import {
  MediaMoveableStyles,
  MOVEABLE_WRAPPER_CLASSNAME,
} from 'modules/tiptap_editor/extensions/media'
import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'
import { CommentStyles } from 'modules/tiptap_editor/styles/commentStyles'
import { CardContentStyles } from 'modules/tiptap_editor/styles/contentStyles'

import { COMMENTS_WRAPPER_CLASSNAME } from '../Annotatable/components/BlockCommentsStack/components/BlockCommentsWrapper'

export const GlobalCardStyles = memo(() => {
  const isMobileDevice = useShouldRenderMobileVersion()
  return (
    <Global
      styles={(theme: Theme) => {
        const globalStyles = {
          [`.${CARD_CONTENT_CLASS}`]: CardContentStyles(isMobileDevice),
          [`.${COMMENTS_WRAPPER_CLASSNAME}`]: CommentStyles,
          [`.${MOVEABLE_WRAPPER_CLASSNAME}`]: MediaMoveableStyles,
        }
        if (!globalStyles) return undefined
        // @ts-ignore
        const styles = css(globalStyles)(theme)
        return styles as Interpolation<{}>
      }}
    />
  )
})
GlobalCardStyles.displayName = 'GlobalCardStyles'
