import { configureStore } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'

import { Card, Doc } from 'modules/api'
import { setCards } from 'modules/cards/reducer'
import { moduleReducers } from 'modules/redux'
import { setDoc } from 'modules/tiptap_editor/reducer'

export type StaticReduxData = Partial<{ cards: Card[]; doc: Doc }>

export const useStaticEditorRedux = (reduxData?: StaticReduxData) => {
  const [store] = useState(() =>
    configureStore({
      reducer: moduleReducers,
    })
  )
  useEffect(() => {
    if (reduxData?.cards) {
      store.dispatch(setCards({ cards: reduxData.cards }))
    }
    if (reduxData?.doc) {
      store.dispatch(setDoc({ doc: reduxData.doc }))
    }
  }, [reduxData, store])
  return store
}
